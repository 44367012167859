<template>
    <div>
        <div class="handle-box">
            <span>
                <el-button type="success" icon="el-icon-refresh" @click="init_logs()">刷新</el-button>
            </span>
            <span style="float: right">
                <span class="demonstration">操作类型：</span>
                <el-select clearable v-model="filter.msgtype" placeholder="状态" class="handle-select mr8"
                           style="width: 120px">
                    <el-option v-for="item in msgType" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
                <span class="demonstration">日期：</span>
                <el-date-picker v-model="setime" type="datetimerange" align="right" unlink-panels range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"></el-date-picker>
                <el-button type="primary" icon="el-icon-search" @click='init_logs()'>查询</el-button>
            </span>
        </div>

        <div class="table">
            <el-table :data="page.list" v-loading="loading" element-loading-text="拼命加载中" border >
                <el-table-column prop="uid" label="用户id" width="100"></el-table-column>
                <el-table-column prop="username" label="用户名" width="150"></el-table-column>
                <el-table-column prop="ip" label="ip" width="150"></el-table-column>
                <el-table-column prop="userAgent" label="浏览器" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="operation" label="描述" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="params" label="参数" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="time" label="耗时(ms)" width="80"></el-table-column>
                <el-table-column prop="addtime" label="操作时间" width="150"></el-table-column>
                <el-table-column prop="status" label="结果" width="80">
                    <template #default="scope">
                        <i :class="STATUS[scope.row.status||0].icon"
                           :style="{color:STATUS[scope.row.status||0].color}">{{
                                STATUS[scope.row.status].name
                            }}</i>
                    </template>
                </el-table-column>
            </el-table>
            <!-- ======分页====== -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="page.pageNum" :page-size="page.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="page.total">
            </el-pagination>
        </div>

    </div>
</template>

<script type="text/javascript">
import {logs} from '../../api/sys/api_tool';
import syscomm from '../../utils/syscomm';
export default {
    data() {
        return {
            // ==========分页=============
            page: {
                list: [],
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            //表格
            loading: false,
            msgType: [{
                name: "全部",
                value: ''
            },
                {
                    name: "增加",
                    value: 'add'
                },
                {
                    name: "编辑",
                    value: 'update'
                },
                {
                    name: "删除",
                    value: 'delete'
                },
                {
                    name: "越权",
                    value: '403'
                },
                {
                    name: "其他",
                    value: 'other'
                },
            ],
            filter: {
                msgtype: undefined
            },

            //弹出框
            setime: ['',''],
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', [syscomm.getStartTime(0.5), new Date()])
                    }
                },
                    {
                        text: '昨天',
                        onClick(picker) {
                            picker.$emit('pick', [syscomm.getStartTime(1), new Date()])
                        }
                    },
                    {
                        text: '近3天',
                        onClick(picker) {
                            picker.$emit('pick', [syscomm.getStartTime(3), new Date()])
                        }
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            picker.$emit('pick', [syscomm.getStartTime(7), new Date()])
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            picker.$emit('pick', [syscomm.getStartTime(30), new Date()])
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            picker.$emit('pick', [syscomm.getStartTime(90), new Date()])
                        }
                    }
                ]
            },
            STATUS: {
                 0:{name: "成功", icon: 'el-icon-circle-check', color: '#30d351'},
                403: {name: "越权", icon: 'el-icon-circle-close', color: '#E6A23C'},
                500: {name: "失败", icon: 'el-icon-circle-close', color: '#DC143C'},
                200: {name: "成功", icon: 'el-icon-circle-check', color: '#30d351'},
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.init_logs();
        },
        // ===========分页===================
        handleSizeChange(val) {
            this.page.pageSize = val;
            this.init_logs();
        },
        handleCurrentChange(val) {
            this.page.pageNum = val;
            this.init_logs();
        },
        init_logs() {
            this.loading = true;
            logs({
                startTime:this.setime[0],
                endTime:this.setime[1],
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
            }).then((res) => {
                this.loading = false;
                if (res.code === 200) {
                    this.page.list = res.pageData.list;
                    this.page.total = res.pageData.total;
                }
            });
        },
    }

}
</script>
<style scoped>

</style>
